import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { FileName } from '../models/FileName.interface';
import { environment } from 'src/environments/environment';

//const CurveBeamCloudBaseURL = 'https://api.mixcloud.com';
let accessToken = localStorage.getItem("accessToken");
const httpOptions = {
  headers: new HttpHeaders({

    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-app-name': 'auth',
    'x-org-name': 'cloud',

  })
};

@Injectable({
  providedIn: 'root'
})
export class DataService {
  nPageOffset = new BehaviorSubject<number>(0);
  bDisplayDotsDropdown = new BehaviorSubject<boolean>(false);
  body: any;
  accessToken = localStorage.getItem("accessToken");
  httpOptionsWithAccessToken: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-app-name': 'main',
      'x-org-name': 'cloud',
    })
  }
  httpOptionsWithAccessTokenUM: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-app-name': 'main',
      'x-org-name': 'cloud',
      'Authorization': 'Bearer ' + this.accessToken,
      // 'x-tenant-admin-mode': 'true'
    })
  }
  httpOptionsForTenantUser: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-app-name': 'main',
      'x-org-name': 'cloud',
      'Authorization': 'Bearer ' + this.accessToken,
      'x-tenant-admin-mode': 'true'
    })
  }
  httpContentType:any = {
    headers: new HttpHeaders({
      'Accept':'*/*',
      'x-app-name': 'main',
        'x-org-name': 'cloud',
        'Authorization': 'Bearer ' + this.accessToken
    })
  }


  httpOptionsOfFileTags: any = {
    headers: new HttpHeaders({
      'Authorization':`Bearer ${localStorage.getItem("accessToken")}`,
      'accept': 'application/json',
      'x-org-name': 'cloud',
      'x-requested-with': 'XMLHttpRequest',
      'x-app-name': 'main',
      'content-type': 'application/json',
      //'origin': 'https://app-dev.cb-portal.trilloapps.com',
      'accept-language': 'en-US,en;q=0.9',
    })
  }

  httpOptionsOfFilSharingAutoMetrics: any = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'x-org-name': 'cloud',
      'x-requested-with':'XMLHttpRequest',
      'x-app-name': 'fm',
      'Content-Type': 'application/json',
      //'origin':' https://app-dev.cb-portal.trilloapps.com',
      'accept-language':'en-US,en;q=0.9',
    })
  }

  httpOptionsForUserAgreement: any = {
    headers: new HttpHeaders({
      'Authorization':`Bearer ${localStorage.getItem("accessToken")}`,
      'accept': 'application/json',
      'x-org-name': 'cloud',
      'x-requested-with': 'XMLHttpRequest',
      'x-app-name': 'main',
      'content-type': 'application/json',
      'accept-language': 'en-US,en;q=0.9',
    })
  }

  constructor(private http: HttpClient) {}

  DataService_ValidateFileSize(nIncommingFileSizeInBytes : number, sIncommingFileName : string,nIncomingFileType : any)
  {
    if(nIncommingFileSizeInBytes <= 1073741824 && (nIncomingFileType === 'application/pdf' || nIncomingFileType === 'application/vnd.oasis.opendocument.spreadsheet' ||
    nIncomingFileType === 'application/vnd.ms-excel' || nIncomingFileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    nIncomingFileType === 'application/msword' || nIncomingFileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    nIncomingFileType === 'text/csv' || nIncomingFileType === 'text/plain' || nIncomingFileType === 'image/png' || nIncomingFileType === 'image/jpeg' ||
    nIncomingFileType === 'image/gif' || nIncomingFileType === 'application/sla' || nIncomingFileType === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' ||
    nIncomingFileType === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' || nIncomingFileType === 'application/vnd.ms-powerpoint' ||
    nIncomingFileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || nIncomingFileType === 'model/stl' || nIncomingFileType === 'model/sla' || 
    nIncomingFileType == 'application/zip' || nIncomingFileType == 'application/xip' || nIncomingFileType == "application/x-zip-compressed"))
    {
      console.log(`
      File size less than 1 Gb ==>
      File name : ${sIncommingFileName}
      File size : ${nIncommingFileSizeInBytes}
      File Type : ${nIncomingFileType}
      `);
      return true;
    }
    else
    {
      console.log(`
      File size less than 1 Gb ==>
      File name : ${sIncommingFileName}
      File size : ${nIncommingFileSizeInBytes}
      File Type : ${nIncomingFileType}
      `);
      return false;
    }
  }
  DataService_ValidateFileSizeForDicom(nIncommingFileSizeInBytes : number, sIncommingFileName : string)
  {
    if(nIncommingFileSizeInBytes <= 1073741824)
    {
      console.log(`
      File size less than 1 Gb ==>
      File name : ${sIncommingFileName}
      File size : ${nIncommingFileSizeInBytes}
      `);
      return true;
    }
    else
    {
      console.log(`
      File size greater than 1 Gb ==>
      File name : ${sIncommingFileName}
      File size : ${nIncommingFileSizeInBytes}
      `);
      return false;
    }
  }


  DataService_CSVBasedSearch(body): Observable<any> {
    //  console.log("CSV Search Body ==> ",body);
    return this.http.post(environment.CurveBeamCloudBaseURL + '/ds/function/shared/CSVBasedSearch', body, this.httpOptionsWithAccessToken);
  }

  DataService_BatchTransferFile(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/ds/function/shared/BatchTransfer', body, httpOptions);
  }

  DataService_NonDicomBatchTransferFile(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/ds/function/shared/FileBatchTransfer', body, httpOptions);
  }

  DataService_StandardSearch(body): Observable<any> {
    
    return this.http.post(environment.CurveBeamCloudBaseURL + '/ds/function/shared/StandardSearch', body, this.httpOptionsWithAccessToken);
  }

  DataService_SaveUserAgreements(body): Observable<any> {
    return this.http.post(environment.CurveBeamCloudBaseURL + '/authds/saveUserAgreements', body, this.httpOptionsForUserAgreement);
  }

  DataService_SendFolderName(body): Observable<any> {
    return this.http.post(environment.CurveBeamCloudBaseURL + '/ds/function/shared/DicomUpload', body, this.httpOptionsWithAccessToken);
  }

  DataService_GetTaggedFiles(body: any): Observable<any> {
    //return this.http.post(environment.CurveBeamCloudBaseURL + '/ds/page', body, httpOptions);
    return this.http.post(environment.CurveBeamCloudBaseURL + '/ds/sqltemplate/shared/common/File', body, this.httpOptionsWithAccessToken);
  }

  DataService_DownloadDicomFile(body: any): Observable<any> {
    return this.http.post(environment.CurveBeamCloudBaseURL + '/ds/function/createDownloadZipFile', body, this.httpOptionsWithAccessToken);
  }
  DataService_GetBatchTransferDataSet(body: any): Observable<any> {
    console.log("GetBatchTransferDataSet BODY ==> ", body);
    return this.http.post(environment.CurveBeamCloudBaseURL + '/ds/function/shared/GetBatchTransferDataSet', body, this.httpOptionsWithAccessToken);
  }
  DataService_DownloadTaggedFile(body: any): Observable<any> {
    console.log("DataService_DownloadTaggedFile: Body => ", body);
    return this.http.post(environment.CurveBeamCloudBaseURL + '/foldersvc/cloudstorage/folder/retieveSignedUrl', body, this.httpOptionsOfFileTags);
  }


  DataService_ShareTaggedFile(body: any): Observable<any> {
    console.log("DataService_DownloadDicomFile: Body => ", body);
    return this.http.post(environment.CurveBeamCloudBaseURL + '/foldersvc/cloudstorage/folder/createDownloadSignedUrl', body, this.httpOptionsOfFilSharingAutoMetrics);
  }

  DataService_UploadDicomFileToAutoMetrics(body: any): Observable<any> {
    console.log("DataService_UploadDicomFileToAutoMetrics: Body => ", body);
    return this.http.post(environment.CurveBeamCloudBaseURL + '/ds/function/shared/AutoMetricsUpload', body, this.httpOptionsWithAccessToken);
  }

  DataService_GetTenant(body: any): Observable<any> {
    return this.http.post(environment.CurveBeamCloudBaseURL + '/ds/page/shared/common/Tenant', `${body}`, httpOptions);
  }

  // DataService_GetSearchResults(phrase: string): Observable<FileName[]> {
  //   let body = {
  //     sqlTemplate: "GetFileName",
  //     size: 100,
  //     start: 1,
  //     className: "FileNames",
  //     "params": {
  //       "value": "" + phrase + "%"
  //     }
  //   };
  //   console.log("Body", JSON.stringify(body));
  //   return this.http
  //     .post(environment.CurveBeamCloudBaseURL + '/ds/sqltemplate/shared/common/FileNames', JSON.stringify(body), httpOptions)
  //     .pipe(pluck('items'));
  // }

  DataService_GetRecentActivity(body): Observable<FileName[]> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/ds/function/shared/GetRecentActivity', body, httpOptions);
  }

  async getPatentInfo(body: any): Promise<Observable<any>> {
    return await this.http.post(environment.CurveBeamCloudBaseURL + '/ds/page/shared/common/curveBeamTest', `${body}`, httpOptions);
  }
  //forget password
  DataService_ForgetPassword(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/_preauthsvc/user/forgotPassword', body, httpOptions);
  }
  //reset password
  DataService_ResetPassword(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/_preauthsvc/user/resetPassword', body, httpOptions);
  }
  //build number
  DataService_GetBuildNumber(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/_systemInfo', body, httpOptions);
  }
  DataService_DownloadFile(data: any, filename = 'CurveBeam Report') {
    let csvData = this.DataService_ConvertToCSV(data, ["seriesUID", "patientBirthday", "bodyPartExamined", "seriesDescription", "aquisitionDate", "numberOfFrames", "manufacturerName"]);
    console.log(csvData);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    console.log('url = ', url);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      // dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    console.log('dwldLink = ', dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  DataService_ConvertToCSV(objArray: any, headerList: any) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }

    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  DataService_SaveUserActivityLog(body: any): Observable<any> {
    return this.http.post(environment.CurveBeamCloudBaseURL + '/ds/function/shared/SaveUserRecentActivity', body, httpOptions);
  }
  DataService_OnBoarding(body: any): Observable<any> {
    return this.http.post(environment.CurveBeamCloudBaseURL + '/ds/function/shared/Onboarding', body, httpOptions);
  }

  DataService_AdvancedSearch(label1: string, label2: string, label3: string, phrase: string, phrase2: string, phrase3: string, size : number,start : number): Observable<any> {
    let body: any = {
      start : start,
      size : size
    };
    // first dropdown
    switch (label1) {
      case "Patient Name":
        body.PatientName = phrase;
        break;
      case "Patient ID":
        body.PatientID = phrase;
        break;
      case "Patient Birthday":
        body.PatientBirthDateRange = phrase;
        break;
      case "Acquisition Date":
        body.AcquisitionDateRange = phrase;
        break;
      case "Device":
        body.Device = phrase;
        break;
      case "SeriesUID":
        body.SeriesInstanceUID = phrase;
        break;
      case "Body Part Examined":
        body.BodyPartExamined = phrase;
        break;
      case "Manufacturer Model Name":
        body.ManufacturerModelName = phrase;
        break;
      case "Accession Number":
        body.AccessionNumber = phrase;
        break;
      case "Gender / Patient’s Sex":
        body.PatientSex = phrase;
        break;
      case "ReconFilter (Convolution Filter)":
        body.ConvolutionKernel = phrase;
        break;
      case "Institution Name":
        body.InstitutionName = phrase;
        break;
      case "Referring Physician’s Name":
        body.ReferringPhysicianName = phrase;
        break;
      case "Requesting Physician":
        body.RequestingPhysician = phrase;
        break;
      default:
        break;
    }
    // second dropdown
    switch (label2) {
      case "Patient Name":
        body.PatientName = phrase2;
        break;
      case "Patient ID":
        body.PatientID = phrase2;
        break;
      case "Patient Birthday":
        body.PatientBirthDateRange = phrase2;
        break;
      case "Acquisition Date":
        body.AcquisitionDateRange = phrase2;
        break;
      case "Device":
        body.Device = phrase2;
        break;
      case "SeriesUID":
        body.SeriesInstanceUID = phrase2;
        break;
      case "Body Part Examined":
        body.BodyPartExamined = phrase2;
        break;
      case "Manufacturer Model Name":
        body.ManufacturerModelName = phrase2;
        break;
      case "Accession Number":
        body.AccessionNumber = phrase2;
        break;
      case "Gender / Patient’s Sex":
        body.PatientSex = phrase2;
        break;
      case "ReconFilter (Convolution Filter)":
        body.ConvolutionKernel = phrase2;
        break;
      case "Institution Name":
        body.InstitutionName = phrase2;
        break;
      case "Referring Physician’s Name":
        body.ReferringPhysicianName = phrase2;
        break;
      case "Requesting Physician":
        body.RequestingPhysician = phrase2;
        break;
      default:
        break;
    }
    // Third dropdown
    switch (label3) {
      case "Patient Name":
        body.PatientName = phrase3;
        break;
      case "Patient ID":
        body.PatientID = phrase3;
        break;
      case "Patient Birthday":
        body.PatientBirthDateRange = phrase3;
        break;
      case "Acquisition Date":
        body.AcquisitionDateRange = phrase3;
        break;
      case "Device":
        body.Device = phrase3;
        break;
      case "SeriesUID":
        body.SeriesInstanceUID = phrase3;
        break;
      case "Body Part Examined":
        body.BodyPartExamined = phrase3;
        break;
      case "Manufacturer Model Name":
        body.ManufacturerModelName = phrase3;
        break;
      case "Accession Number":
        body.AccessionNumber = phrase3;
        break;
      case "Gender / Patient’s Sex":
        body.PatientSex = phrase3;
        break;
      case "ReconFilter (Convolution Filter)":
        body.ConvolutionKernel = phrase3;
        break;
      case "Institution Name":
        body.InstitutionName = phrase3;
        break;
      case "Referring Physician’s Name":
        body.ReferringPhysicianName = phrase3;
        break;
      case "Requesting Physician":
        body.RequestingPhysician = phrase3;
        break;
      default:
        break;
    }
    return this.http.post(environment.CurveBeamCloudBaseURL + '/ds/function/shared/AdvancedSearch', body, this.httpOptionsWithAccessToken);
  }
  // user management api's
   //users list
   DataService_GetUsersList(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/ds/page/auth/vault', body, this.httpOptionsWithAccessTokenUM);
  }
   //upload  users
   DataService_UploadUsers(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/authds/uploadUsers', body , this.httpContentType );

  }
   //create user
   DataService_CreateUser(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/_service/um/newUser', body, this.httpOptionsWithAccessTokenUM);
  }
   //create tenant user
   DataService_CreateTenantUser(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/_service/um/newUser', body, this.httpOptionsForTenantUser);
  }
  //create tenant roles
  DataService_ConfigureVendorAccess(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/ds/function/shared/AssignTenantRoles/handle', body, this.httpOptionsForTenantUser);
  }
  //get tenant roles
  DataService_GetVendorAccess(body): Observable<any> {
  return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/ds/function/shared/GetTenantRoles/handle', body, this.httpOptionsForTenantUser);
  }
   //edit user
   DataService_EditUser(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/_service/um/editUser', body, this.httpOptionsWithAccessTokenUM);
  }
     //edit Tenant user
     DataService_EditTenantUser(body): Observable<any> {
      return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/_service/um/editUser', body, this.httpOptionsForTenantUser);
    }
    //reset user password
    DataService_ResetUserPassword(body): Observable<any> {
      return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/_service/um/resetPassword', body, this.httpOptionsWithAccessTokenUM);
    }
      //suspend user
   DataService_SuspendUser(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/_service/um/toggleSuspendActive', body, this.httpOptionsWithAccessTokenUM);
  }
   //delete user
   DataService_DeleteUser(id : any): Observable<any> {

    return this.http.delete<any>(environment.CurveBeamCloudBaseURL + '/_service/um/deleteUser' + "?id="+ id , this.httpOptionsWithAccessTokenUM  );
  }
    //roles list
    DataService_GetRolesList(body): Observable<any> {
      return this.http.get<any>(environment.CurveBeamCloudBaseURL + '/_service/um/getRoles', httpOptions);
    }
    // new user role
    DataService_GetNewUserRole(body): Observable<any> {
      return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/_service/um/saveRole', body , this.httpOptionsWithAccessTokenUM);
    }
     // edit  role
     DataService_GetEditRole(body): Observable<any> {
      return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/_service/um/saveRole', body , this.httpOptionsWithAccessTokenUM);
    }
     //logs list
  DataService_GetLogsList(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/ds/page/AuditLog', body, this.httpOptionsWithAccessTokenUM);
  }
     //logs details list
     DataService_GetLogsDestailsList(id : any): Observable<any> {

      return this.http.get<any>(environment.CurveBeamCloudBaseURL + '/ds/object/shared/common/AuditLog' + "?id="+id, this.httpOptionsWithAccessTokenUM );
    }

  //groups list
  DataService_GetUsersGroup(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/ds/sqltemplate', body, this.httpOptionsWithAccessTokenUM);
  }

  //new group
  DataService_AddNewGroup(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/function/shared/newGroup', body, this.httpOptionsWithAccessTokenUM);
  }
  //delete group
  DataService_DeleteGroup(body): Observable<any>
  {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/foldersvc/cloudstorage/group/delete', body, this.httpOptionsWithAccessTokenUM);
  }
  //total groups
  DataService_GetTotalGroups(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/function/shared/newGroup', body, httpOptions);
  }
  //add member (groups)
  DataService_AddMember(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/function/shared/addMember', body, this.httpOptionsWithAccessTokenUM);
  }
    //delete member (groups)
    DataService_DeleteMember(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/function/shared/deleteMember', body, this.httpOptionsWithAccessTokenUM);
  }
    //update member (groups)
    DataService_UpdateMember(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/function/shared/updateMember', body, this.httpOptionsWithAccessTokenUM);
  }
    // tenants
    //get total tenants list(sidebar)
  DataService_GetTotalTenants(body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/ds/page/auth/vault', body, this.httpOptionsWithAccessTokenUM);
  }
      //get selected tenants data
  DataService_GetTenantsData(body): Observable<any> {
  return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/ds/page/auth/vault', body, this.httpOptionsWithAccessTokenUM);
  }
  //add new tenant
  DataService_AddNewTenant(body): Observable<any> {
  return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/ds/save/auth/vault/Org', body, this.httpOptionsWithAccessTokenUM);
  }

  //Get All Studies Records
  DataService_GetAllStudiesRecordsList(body): Observable<any> {

  return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/ds/function/shared/GetAllStudies/handle', body, this.httpOptionsWithAccessTokenUM );
}

 //Get All Files Records
 DataService_GetAllFilesRecordsList(body): Observable<any> {
  return this.http.post<any>(environment.CurveBeamCloudBaseURL + '/ds/function/shared/GetFiles', body , this.httpOptionsWithAccessTokenUM );
}

//Get All Files Records
//   DataService_GetAllFilesRecordsList(): Observable<any> {

//   return this.http.get<any>(environment.CurveBeamCloudBaseURL + '/ds/page/shared/common/File', this.httpOptionsWithAccessTokenUM );
// }

   //Restor3d

  DataService_Restor3d (body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL +'/ds/function/shared/Restor3dUpload' , body , this.httpOptionsWithAccessTokenUM )
  }
  DataService_DeleteDICOMSeries (body): Observable<any> {
  return this.http.post<any>(environment.CurveBeamCloudBaseURL +'/ds/function/shared/DicomDelete' , body , this.httpOptionsWithAccessTokenUM )
  }
  DataService_VentCreativity (body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL +'/ds/function/shared/VentCreativityUpload' , body , this.httpOptionsWithAccessTokenUM )
  }
  DataService_DownloadCSVUserList (body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL +'/ds/function/shared/GetUserCSV' , body , this.httpOptionsWithAccessTokenUM )
  }
  DataService_DownloadCSVRolesList (body): Observable<any> {
    return this.http.post<any>(environment.CurveBeamCloudBaseURL +'/ds/function/shared/GetRoleCSV' , body , this.httpOptionsWithAccessTokenUM )
  }

}
